<template>
  <span v-b-visible.once="visible">
    <span v-if="loading">
      <b-spinner small />
      <span>Loading invoice...</span>
    </span>
    <b-badge v-if="err" variant="danger">{{ err }}</b-badge>
    <span v-if="invoice">
      <span><b-badge variant="warning">{{ parseFloat(invoice.summary.sumRounded) }} without TAX</b-badge></span>
      &nbsp;<span><b-badge variant="warning">{{ parseFloat(invoice.fines) }} fines</b-badge></span> <br>

      <b-badge variant="primary"><span v-if="invoice.booking.number">{{ invoice.booking.number }}</span></b-badge>
      <span v-if="invoice.booking.firstname"> • {{ invoice.booking.firstname }}</span>
      <span v-if="invoice.booking.lastname"> • {{ invoice.booking.lastname }}</span>
      <span v-if="invoice.booking.companyname"> • {{ invoice.booking.companyname }}</span><br>
      <!-- &nbsp;<span><b-badge variant="warning">{{ parseFloat(invoice.summary.sumTaxedRounded) }} w/TAX</b-badge></span> --->
    </span>
  </span>
</template>

<script>
export default {
  props: {
    invoiceId: {
      required: true,
      type: String
    },
  },
  data () {
    return {
      loading: true,
      err: null,
      invoice: null
    };
  },
  methods: {
    visible (isVisible) {
      if (!isVisible) {
        return;
      }
      this.$http.get(`/invoices/${this.invoiceId}`, { timeout: 10000, noLoading: true })
        .then(({ data }) => {
          this.invoice = data;
          this.$emit('invoice', this.invoice);
        })
        .catch(err => {
          this.err = err.response && err.response.data ? err.response.data.message || err.message : err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
