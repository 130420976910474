<template>
  <span v-b-visible.once="visible">
    <span v-if="loading">
      <b-spinner small />
      <span v-if="draft">Generating draft...</span>
      <span v-if="!draft">Generating invoice...</span>
    </span>
    <b-badge v-if="err" variant="danger">{{ err }}</b-badge>
    <span v-if="invoice">
      {{ invoice.startDate | moment('L') }}-{{ invoice.endDate | moment('L') }}<br>
      <span><b-badge variant="warning">{{ parseFloat(invoice.summary.sumRounded) }} without TAX</b-badge></span>
      &nbsp;<span><b-badge variant="warning">{{ parseFloat(invoice.fines) }} fines</b-badge></span>
      <!-- &nbsp;<span><b-badge variant="warning">{{ parseFloat(invoice.summary.sumTaxedRounded) }} w/TAX</b-badge></span> --->
    </span>
  </span>
</template>

<script>
export default {
  props: {
    wizardId: {
      required: true,
      type: String
    },
    date: {
      required: true,
      type: String
    },
    bookingId: {
      required: true,
      type: String
    },
    draft: Boolean
  },
  data () {
    return {
      loading: true,
      err: null,
      invoiceType: 'MONTHLY',
      invoice: null
    };
  },
  methods: {
    visible (isVisible) {
      if (!isVisible) {
        return;
      }
      const params =  {
        invoiceType: this.invoiceType,
        date: this.date,
        wizardId: this.wizardId,
      };
      const promise = this.draft ?
        this.$http.get(`/bookings/${this.bookingId}/invoices/draft`, { params, timeout: 10000, noLoading: true }) :
        this.$http.post(`/bookings/${this.bookingId}/invoices`, params, { timeout: 10000, noLoading: true });
      promise
        .then(({ data }) => {
          if (data.success && data.id) { // POST response is with invoice ID
            return this.$http.get(`/bookings/${this.bookingId}/invoices/${data.id}`, { noLoading: true })
              .then(({ data }) => data);
          } else {
            // draft response
            return { ...data, draft: true };
          }
        })
        .then((invoice) => {
          this.invoice = invoice;
          this.$emit('invoice', invoice);
        })
        .catch(err => {
          this.err = err.response && err.response.data ? err.response.data.message || err.message : err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
