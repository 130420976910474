<template>
  <span v-b-visible.once="visible">
    <b-spinner v-if="loading" small />
    <span v-if="item">
      <b-badge variant="dark">
        {{ item.name }}
      </b-badge><br>
      <b-badge v-if="item.status === 'ERROR'" variant="danger">
        Error
      </b-badge>
      <b-badge v-if="item.status === 'CANCELED'" variant="warning">
        Canceled
      </b-badge>
      <b-badge v-if="item.status === 'SCHEDULED'" variant="warning">
        Scheduled
      </b-badge>
      <b-badge v-if="item.status === 'RESCHEDULED'" variant="warning">
        Rescheduled
      </b-badge>
      <b-badge v-if="item.status === 'OK'" variant="success">
        Done
      </b-badge>
      <b-badge v-if="item.output && item.output.error && item.output.message" variant="danger">
        {{ item.output.message }}
      </b-badge>
      <b-badge v-if="error" variant="danger">
        {{ error }}
      </b-badge>
      <br><b-badge><small><code>{{ item.output }}</code></small></b-badge>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    invoiceId: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      loading: true,
      item: null,
      error: null
    };
  },
  methods: {
    visible (isVisible) {
      if (!isVisible) {
        return;
      }
      this.$http.get(`invoices/${this.invoiceId}/jobs`, {
        params: {
          limit: 1,
          sort: '-scheduled'
        },
        noLoading: true
      }).then(({ data }) => {
        if (data && data.length) {
          this.item = data[0];
        }
      }).catch(this.err).finally(() => {
        this.loading = false;
      });
    },
    err (err) {
      this.error = err.response && err.response.data ? err.response.data.message || err.message : err.message;
    },
  },

};
</script>
