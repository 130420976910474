<template>
  <div v-if="modules && !!modules['invoices']" class="row">
    <div class="col">
      <b-card title="Invoices Wizards">
        <blueprint-data ref="data" api-route-path="invoices/wizards" :fields="fields" no-edit no-delete>
          <template v-slot:new="{ }">
            <b-button variant="success" @click="newItem">
              <fa-icon icon="plus" /> New Periodic Wizard
            </b-button>
          </template>
          <template v-slot:actions="row">
            <a href="#" @click.prevent="reReun(row.item)"><fa-icon icon="cogs" />Re-run</a>
            <a href="#" @click.prevent="generatedInvoices(row.item)"><fa-icon icon="file-invoice" />Generated invoices</a>
          </template>
        </blueprint-data>

        <b-modal ref="new" size="lg" no-enforce-focus hide-header>
          <form @submit.prevent="save">
            <b-form-group label="Name">
              <b-form-input v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <b-form-group label="Date">
              <DatePicker v-model="item.date" :state="state('date')" input-class="form-control" />
            </b-form-group>
            <input type="submit" style="visibility: hidden;">
          </form>
          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="dark" @click="cancel()">
              Cancel
            </b-button>
            <b-button :disabled="!(item.name && item.date)" variant="success" @click="save()">
              Next
            </b-button>
          </template>
        </b-modal>

        <b-modal ref="pickBookings" size="lg" no-enforce-focus title="Select bookings for invoice generation">
          <b-alert :show="true" variant="warning">
            Generating invoces with date {{ item.date | moment('L') }}
          </b-alert>
          <PeriodicWizardBookings :wizard-id="item.id" :date="item.date" @selected="(data) => selectedBookings = data" />
          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="dark" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="success" :disabled="selectedBookings.length === 0" @click="generateDrafts()">
              Next
            </b-button>
          </template>
        </b-modal>

        <b-modal ref="generateDrafts" size="lg" no-enforce-focus title="Select Generated Invoices drafts to generate invoices">
          <b-alert :show="true" variant="warning">
            Generating invoces with date {{ item.date | moment('L') }}
          </b-alert>
          <PeriodicWizardDrafts :wizard-id="item.id" :bookings="selectedBookings" :date="item.date" @selected="(data) => selectedDrafts = data" />

          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="dark" @click="reReun(item)">
              Back
            </b-button>
            <b-button variant="dark" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="success" :disabled="selectedDrafts.length === 0" @click="generateInvoices()">
              Next
            </b-button>
          </template>
        </b-modal>

        <b-modal ref="generateInvoices" size="lg" no-enforce-focus title="Select Generated Invoices to confirm">
          <b-alert :show="true" variant="warning">
            Generated invoices with date {{ item.date | moment('L') }}
          </b-alert>
          <b-overlay
            id="overlay-background"
            :show="progress"
            variant="light"
            :opacity="0.9"
            :blur="'2px'"
            :rounded="false"
          >
            <template v-slot:overlay>
              <div style="min-width:300px">
                <b-progress
                  height="2rem"
                  :value="progressValue"
                  :max="progressMax"
                  show-progress
                  animate
                  show-value
                  striped
                  animated
                />
              </div>
            </template>
            <PeriodicWizardInvoices :wizard-id="item.id" :bookings="selectedDrafts" :date="item.date" @selected="(data) => selectedInvoices = data" />
          </b-overlay>
          <template v-slot:modal-footer="{ }">
            <b-button variant="danger" :disabled="selectedInvoices.length === 0 || progress" @click="cancelConfirmInvoicesWizard()">
              Cancel invoices
            </b-button>
            <b-button variant="warning" :disabled="selectedInvoices.length === 0 || progress" @click="cancelConfirmInvoicesWizard('CONFIRMED')">
              Confirm invoices
            </b-button>
          </template>
        </b-modal>

        <b-modal ref="generatedInvoices" size="lg" no-enforce-focus title="Wizard generated invoices">
          <b-overlay
            id="overlay-background"
            :show="progress"
            variant="light"
            :opacity="0.9"
            :blur="'2px'"
            :rounded="false"
          >
            <template v-slot:overlay>
              <div style="min-width:300px">
                <b-progress
                  height="2rem"
                  :value="progressValue"
                  :max="progressMax"
                  show-progress
                  animate
                  show-value
                  striped
                  animated
                />
              </div>
            </template>
            <PeriodicWizardGeneratedInvoices v-if="reload === false" :wizard-id="item.id" @selected="(data) => selectedGeneratedInvoices = data" />
          </b-overlay>
          <template v-slot:modal-footer="{ cancel }">
            <b-button variant="dark" @click="cancel()">
              Close
            </b-button>
            <b-button variant="danger" :disabled="selectedGeneratedInvoices.length === 0 || progress" @click="cancelConfirmInvoices()">
              Cancel invoices
            </b-button>
            <b-button variant="warning" :disabled="selectedGeneratedInvoices.length === 0 || progress" @click="cancelConfirmInvoices('CONFIRMED')">
              Confirm invoices
            </b-button>
            <InvoiceWizardEmailButton :disabled="selectedGeneratedInvoices.length === 0 || progress" :selected-invoices="selectedGeneratedInvoices" @done="reloadData" @progress="progressBar" />
          </template>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import DatePicker from '../../../components/date-picker';
import PeriodicWizardBookings from '../components/periodic-wizard-bookings';
import PeriodicWizardDrafts from '../components/periodic-wizard-drafts';
import PeriodicWizardInvoices from '../components/periodic-wizard-invoices';
import PeriodicWizardGeneratedInvoices from '../components/periodic-wizard-generated-invoices';
import InvoiceWizardEmailButton from '../../booking_notifications/components/invoice-wizard-email-button.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCogs, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

library.add(faCogs, faFileInvoice);

export default {
  components: {
    DatePicker,
    PeriodicWizardBookings,
    PeriodicWizardDrafts,
    PeriodicWizardInvoices,
    PeriodicWizardGeneratedInvoices,
    InvoiceWizardEmailButton
  },
  data () {
    return {
      moment,
      progressMax: 0,
      progressValue: 0,
      progress: false,
      reload: false,
      item: {},
      selectedBookings: [],
      selectedDrafts: [],
      selectedInvoices: [],
      selectedGeneratedInvoices: [],
      errors: {},
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'date',
          label: 'Date',
          formatter: (value, key, item) => {
            return moment(item.date).utc().format(DatePicker.format());
          }
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    generatedInvoices (item) {
      this.item = item;
      return this.$refs.generatedInvoices.show();
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    newItem () {
      this.errors = {};
      this.item = {};
      this.$refs.new.show();
    },
    reReun (item) {
      this.item = item;
      Promise.resolve()
        .then(() => this.$refs.pickBookings.hide())
        .then(() => this.$refs.generateDrafts.hide())
        .then(() => this.$refs.pickBookings.show());
    },
    generateDrafts () {
      Promise.resolve()
        .then(() => this.$refs.pickBookings.hide())
        .then(() => this.$refs.generateDrafts.show());
    },
    generateInvoices () {
      Promise.resolve()
        .then(() => {
          for (let invoiceDraft of this.selectedDrafts) {
            if (!invoiceDraft.invoiceDraft) {
              throw new Error('You have seleced row were invoice draft is not generated');
            }
          }
        })
        .then(() => this.$refs.generateDrafts.hide())
        .then(() => this.$refs.generateInvoices.show())
        .catch(this.err);
    },
    save () {
      this.$http.post(`invoices/wizards`, this.item)
        .then(({ data }) => {
          this.item.id = data.id;
          return this.$refs.new.hide();
        })
        .then(() => this.$refs.pickBookings.show())
        .then(() => this.$refs.data.getData())
        .catch(this.err);
    },
    cancelConfirmInvoicesWizard (status = 'CANCELLED') {
      Promise.resolve()
       .then(async () => {
         for (let invoice of this.selectedInvoices) {
           if (!invoice.invoice) {
             throw new Error('You have seleced row were invoice is not generated');
           }
         }
        let i = 0;
        for (let item of this.selectedInvoices) {
          i += 1;
          await this.$http.patch(`/bookings/${item.id}/invoices/${item.invoice.id}`, { status }, { noLoading: true }).catch(this.err);
          this.progressBar(i, this.selectedInvoices.length);
        }
       })
      .then(() => this.$refs.generateInvoices.hide())
      .then(() => this.generatedInvoices(this.item))
      .catch(this.err);
    },
    progressBar (val, max) {
        this.progressMax = max;
        this.progressValue = val;
        this.progress = val < max;
    },
    async cancelConfirmInvoices (status = 'CANCELLED') {
      let i = 0;
      for (let item of this.selectedGeneratedInvoices) {
          i += 1;
          await this.$http.patch(`/bookings/${item.bookingId}/invoices/${item.id}`, { status }, { noLoading: true }).catch(this.err);
          this.progressBar(i, this.selectedGeneratedInvoices.length);
      }
      this.reloadData();
    },
    reloadData () {
      this.reload = true;
      this.$nextTick(() => {
        this.reload = false;
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  },
};
</script>
