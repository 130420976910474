<template>
  <div>
    <blueprint-data
      api-route-path="bookings" :params="params" :fields="fields" no-new no-actions :where="where"
      limit-per-page="10000"
      no-limit
      no-export
      selectable
      select-mode="multi"
      @selected="onRowSelected"
    >
      <template v-slot:search>
        <b-form-group label-cols="4" label="Properties">
          <blueprint-search-input
            id="properties"
            v-model="properties"
            :multiple="true"
            placeholder="Type to search Properties"
            label="name"
            track-by="id"
            api-route-path="inventory/properties"
            @input="buildWhere"
          />
        </b-form-group>
        <b-form-group label-cols="4" label="Units">
          <blueprint-search-input
            id="units"
            v-model="units"
            :multiple="true"
            placeholder="Type to search Units"
            label="name"
            track-by="id"
            :where="unitsWhere"
            api-route-path="inventory/units"
            @input="buildWhere"
          />
        </b-form-group>
      </template>

      <template v-slot:number="{ item }">
        {{ item.number }}
      </template>
      <template v-slot:range="{ item }">
        {{ item.range.start | moment('L') }}-{{ item.range.end | moment('L') }}
      </template>
      <template v-slot:unitId="{ item }">
        {{ item.unit.name }} / {{ item.unit.property.name }}
      </template>
      <template v-slot:price_tax="{ item }">
        {{ parseFloat(parseFloat(item.price) + parseFloat(item.price) * parseFloat(item.tax.percentage)/100).toFixed(2) }}/{{ item.priceMethod }}
      </template>
      <template v-slot:price="{ item }">
        {{ parseFloat(item.price).toFixed(2) }}/{{ item.priceMethod }}
      </template>
    </blueprint-data>
  </div>
</template>

<script>
import utils from '../../../libs/utils.vue';

export default {
  props: ['date'],
  data () {
    return {
      data: [],
      errors: {},
      properties: [],
      units: [],
      params: {},
      status: 'ACTIVATED',
      where: {},
      unitsWhere: {},
      fields: [
        {
          key: 'number',
          label: 'No.',
          sortable: true,
          slot: true,
        },
        {
          key: 'firstname',
          label: 'Firstname',
          sortable: true,
        },
        {
          key: 'lastname',
          label: 'Lastname',
          sortable: true,
        },
        {
          key: 'companyname',
          label: 'Company',
          sortable: true,
        },
        {
          key: 'unitId',
          label: 'Unit / Property',
          sortable: true,
          slot: true,
        },
        {
          key: 'range',
          label: 'Start - End',
          sortable: true,
          slot: true,
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
          slot: true,
        },
        {
          key: 'price_tax',
          label: 'Price w/tax',
          sortable: false,
          slot: true,
        },
      ],
    };
  },
  watch: {
    properties () {
      this.units= [];
    }
  },
  created () {
    this.buildWhere();
  },
  methods: {
    onRowSelected (items) {
      this.$emit('selected', items);
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    buildWhere () {
      this.unitsWhere = {
        ...utils.parseWhereItem('propertyId', this.properties),
      };
      const params = {};
      // TODO: limit with date
      /*
      if (this.start){
        params.start = this.start;
      }
      if (this.end){
        params.end = this.end;
      }
      */
      this.params = params;
      this.where = {
        ...utils.parseWhereItem('status', this.status),
        ...utils.parseWhereItem('unitId', this.units),
        ...utils.parseWhereItem('unit.propertyId', this.properties),
      };
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },

  },
};
</script>
