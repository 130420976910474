<template>
  <b-button v-if="modules && !!modules['booking_notifications']" :disabled="disabled" @click.prevent="emailInvoices">
    E-mail invoices
  </b-button>
</template>

<script>
export default {
  props: {
    selectedInvoices: {
      type: Array,
      default () {
        return [];
      },
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      defaultEmail: null,
      emailRadio: null,
      emailInput: ''
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    emailInvoices () {
      const scheduled = new Date();
      Promise.resolve()
      .then(async () => {
        for (let invoice of this.selectedInvoices) {
          if (!invoice.invoiceData) {
            throw new Error('You have seleced row were invoice is not generated');
          }
          if (invoice.invoiceData.status !== 'CONFIRMED') {
            throw new Error('Only confirmed invoices can be emailed');
          }
        }
        let i = 0;
        for (let item of this.selectedInvoices) {
          i += 1;
          await this.$http.post(`/bookings/${item.bookingId}/invoices/${item.invoiceData.id}/email`, { scheduled }, { noLoading: true });
          this.$emit('progress', i, this.selectedInvoices.length);
        }
      }).then(() => {
        this.$emit('done');
      })
      .catch(this.err);
  },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  }
};
</script>
