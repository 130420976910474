<template>
  <div>
    <b-sidebar
      id="viewinvoice"
      width="60vw"
      :title="invoice ? invoice.refNumber : 'Invoice'"
      lazy backdrop shadow
    >
      <Invoice v-if="invoice" no-edit :item="invoice" @click.prevent />
      <div v-else>
        Invoice not loaded
      </div>
    </b-sidebar>

    <b-button-toolbar class="float-right perpage" key-nav>
      <div right>
        <b-button-group>
          <b-button size="sm" variant="outline-primary" @click="$refs.top.selectAllRows()">
            Select all
          </b-button>
          <b-button size="sm" variant="outline-primary" @click="$refs.top.clearSelected()">
            Clear selected
          </b-button>
        </b-button-group>
      </div>
    </b-button-toolbar>

    <b-table
      ref="top" hover small caption-top responsive empty-html="No items to show"
      :fields="fields" :items="bookings"
      :borderless="true" bvwsdwefvt striped
      show-empty
      :selectable="true"
      select-mode="multi"
      @row-selected="onRowSelected"
    >
      <template v-slot:cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <fa-icon :icon="['far', 'check-square']" />
        </template>
        <template v-else>
          <fa-icon :icon="['far', 'square']" />
        </template>
      </template>
      <template v-slot:cell(booking)="{ item }">
        {{ item.number }}
        <span v-if="item.firstname"> • {{ item.firstname }}</span>
        <span v-if="item.lastname"> • {{ item.lastname }}</span>
        <span v-if="item.companyname"> • {{ item.companyname }}</span><br>
        <span><b-badge variant="warning">{{ parseFloat(item.price) }}/{{ item.priceMethod }} without TAX</b-badge></span>
      </template>
      <template v-slot:cell(unit)="{ item }">
        {{ item.unit.name }} • {{ item.unit.property.name }}
      </template>
      <template v-slot:cell(invoice)="{ item }">
        <PeriodicWizardItem :date="date" :wizard-id="wizardId" :booking-id="item.id" @invoice="(invoice) => { item.invoice = invoice; }" />
      </template>
      <template v-slot:cell(view)="{ item }">
        <a href="#" @click.prevent="invoice = item.invoice"><span v-b-toggle.viewinvoice><fa-icon icon="file-alt" /> view</span></a>
      </template>
    </b-table>
  </div>
</template>


<script>
import PeriodicWizardItem from './periodic-wizard-item';
import Invoice from './invoice.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faFileAlt);

export default {
  components: {
    PeriodicWizardItem,
    Invoice
  },
  props: ['bookings', 'date', 'wizardId'],
  data () {
    return {
      invoice: null,
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false,
        },
        {
          key: 'booking',
          label: 'Booking',
          sortable: false,
        },
        {
          key: 'unit',
          label: 'Unit',
          sortable: false,
        },
        {
          key: 'invoice',
          label: 'Invoice',
          sortable: false,
        },
         {
          key: 'view',
          label: 'View',
          sortable: false,
        }
      ]
    };
  },

  methods: {
    onRowSelected (items) {
      this.$emit('selected', items);
    }
  },

};
</script>
